import React from 'react'
import styled, { withTheme } from 'styled-components'
import { down } from 'styled-breakpoints'
import { useMediaQuery } from 'react-responsive'

import content from '../../content.json'

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.p`
  width: 100%;
  font-family: ${props => props.theme.fonts.text};
  line-height: 50px;
  font-size: 40px;
  color: black;

  span {
    position: relative;
    margin: 0 -10px;
    padding: 0 10px;

    span {
      z-index: 2;
    }

    div {
      position: absolute;
      top: 60%;
      left: 0;
      width: 100%;
      height: 40%;
      background-color: #e5eeff;
    }
  }

  ${down('tablet')} {
    line-height: 45px;
    font-size: 35px;
  }

  ${down('mobile')} {
    line-height: 40px;
    font-size: 30px;
  }
`

const Headline = props => {
  const isMobile = useMediaQuery({
    maxWidth: 600
  })

  let html = content.headline
    .split(' ')
    .map(word => {
      if (word[0] === '^') {
        return `<span><span>${word.substring(1)}</span><div></div></span>`
      } else {
        return word
      }
    })
    .join(' ')
  if (isMobile) {
    html = html.replace(/\n/g, ' ')
  } else {
    html = html.replace(/\n/g, '<br/>')
  }

  return (
    <Container>
      <Content dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  )
}

export default withTheme(Headline)
