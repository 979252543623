import React from 'react'
import styled, { withTheme } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import content from '../../content.json'
import Logo from '../Logo'
import Button from '../Button'

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const Navbar = props => {
  const isTablet = useMediaQuery({ minWidth: props.theme.breakpoints.tablet })

  return (
    <Container>
      <Logo />
      {isTablet && (
        <Button title={content.letsTalk} onClick={props.onButtonClick} />
      )}
    </Container>
  )
}

export default withTheme(Navbar)
