import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import Reset from './Reset'
import ScrollToTop from './ScrollToTop'
import theme from './theme'

import Landing from './pages/Landing'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Code Arrows</title>
      </Helmet>
      <Reset />
      <Router>
        <>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Landing} />

            <Redirect to="/" />
          </Switch>
        </>
      </Router>
    </ThemeProvider>
  )
}

export default App
