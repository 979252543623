import React from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'

import bullet from './images/bullet.svg'

const Container = styled.div`
  display: flex;
  width: fit-content;

  ${props =>
    props.onClick &&
    `
    cursor: pointer;
  `}
`

const Content = styled.p`
  font-family: ${props => props.theme.fonts.text};
  line-height: 25px;
  font-size: 20px;
  color: black;

  ${props =>
    props.faded &&
    `
    color: rgba(0, 0, 0, 0.5);
  `}

  ${props =>
    props.bold &&
    `
    font-weight: 700;
  `}

  ${down('mobile')} {
    line-height: 20px;
    font-size: 16px;
  }
`

const Bullet = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 25px;
  margin-right: 10px;
  background-image: url(${bullet});
  background-size: contain;
  background-position: center;

  ${down('mobile')} {
    height: 20px;
  }
`

const Indent = styled.div`
  width: ${props => props.indent}px;
`

const Text = props => {
  return (
    <Container onClick={props.onClick}>
      {props.indent > 0 && <Indent indent={props.indent} />}
      {props.bullet && <Bullet />}
      <Content faded={props.faded} bold={props.bold}>
        {props.text}
      </Content>
    </Container>
  )
}

export default Text
