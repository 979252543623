export default {
  colors: {
    blue: '#005AFF'
  },
  transitions: {
    fast: '0.1s',
    medium: '0.2s',
    slow: '0.3s'
  },
  breakpoints: {
    mobile: 350,
    tablet: 750,
    desktop: 1000,
    max: 1200
  },
  fonts: {
    text: 'Inconsolata, monospace'
  }
}
