import React from 'react'
import styled, { withTheme } from 'styled-components'
import { down } from 'styled-breakpoints'

import background from '../images/background.png'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 300px;
  position: relative;
`

const Body = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => props.theme.breakpoints.max}px;
  flex: 1;
  min-height: 100%;
  background-color: white;

  border-left: 10px solid black;

  padding: 60px;

  ${down('tablet')} {
    padding: 60px 30px;
  }

  ${down('mobile')} {
    padding: 40px 20px;
  }
`

const BackgroundContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
`

const BackgroundLeft = styled.div`
  height: 100%;
  width: 50%;
  background: black url(${background});
`

const BackgroundRight = styled.div`
  height: 100%;
  width: 50%;
  background-color: white;
`

function Page(props) {
  return (
    <Container>
      <BackgroundContainer>
        <BackgroundLeft></BackgroundLeft>
        <BackgroundRight></BackgroundRight>
      </BackgroundContainer>
      <Body>{props.children}</Body>
    </Container>
  )
}

const PageComponent = withTheme(Page)

export default PageComponent
