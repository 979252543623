import React from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'

const Container = styled.div`
  font-family: ${props => props.theme.fonts.text};
  line-height: 30px;
  font-size: 20px;
  font-weight: 700;
  color: white;
  width: fit-content;

  background-color: ${props => props.theme.colors.blue};
  border-radius: 2px;

  padding: 5px 20px;

  cursor: pointer;
  transition: transform ${props => props.theme.transitions.fast};

  &:hover {
    transform: translateY(-3px);
  }

  ${down('mobile')} {
    font-size: 18px;
  }
`

const Button = props => {
  return <Container onClick={props.onClick}>{props.title}</Container>
}

export default Button
