import React from 'react'
import { withTheme } from 'styled-components'
import { Helmet } from 'react-helmet'
import MediaQuery from 'react-responsive'

import content from '../../content.json'
import Page from '../Page'

import Navbar from '../../components/Navbar'
import Headline from '../../components/Headline'
import Footer from '../../components/Footer'
import Text from '../../components/Text'
import Button from '../../components/Button'
import Space from '../../components/Space'

class Landing extends React.Component {
  state = {}

  componentDidMount() {}

  letsTalk() {
    window.open('mailto:hello@codearrows.com', '_self')
  }

  render() {
    return (
      <Page>
        <Helmet>
          <title>Code Arrows</title>
        </Helmet>

        <Navbar onButtonClick={this.letsTalk.bind(this)} />

        <Space height={[150, 150, 100]} />

        <Headline />

        <Space height={50} />

        <Text text={content.services.text} />
        <Space height={20} />
        {content.services.list.map((service, index) => (
          <div key={index}>
            <Text text={service} bullet indent={10} />
            {index < content.services.list.length - 1 && <Space height={10} />}
          </div>
        ))}

        <MediaQuery maxWidth={this.props.theme.breakpoints.tablet - 1}>
          <Space height={50} />
          <Button title={content.letsTalk} onClick={this.letsTalk.bind(this)} />
        </MediaQuery>

        <Space grow height={[150, 150, 100]} />
        <Footer onEmailClick={this.letsTalk.bind(this)} />
      </Page>
    )
  }
}

export default withTheme(Landing)
