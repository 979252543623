import React from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'

import logo from './images/logo.svg'

const Logo = styled.div`
  width: 220px;
  height: 40px;
  background-image: url(${logo});
  background-size: contain;
  background-position: center;
  cursor: pointer;

  ${down('mobile')} {
    width: 165px;
    height: 30px;
  }
`

export default Logo
