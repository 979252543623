import React from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'

const Space = styled.div.attrs(props => {
  let spaceHeight = {
    desktop: 0,
    tablet: 0,
    mobile: 0
  }

  let spaceWidth = {
    desktop: 0,
    tablet: 0,
    mobile: 0
  }

  if (props.height) {
    if (props.height instanceof Array) {
      spaceHeight.desktop = props.height[0]
      spaceHeight.tablet = props.height[1] || props.height[0]
      spaceHeight.mobile = props.height[2] || props.height[1] || props.height[0]
    } else {
      spaceHeight.desktop = props.height
      spaceHeight.tablet = props.height
      spaceHeight.mobile = props.height
    }
  }

  if (props.width) {
    if (props.width instanceof Array) {
      spaceWidth.desktop = props.width[0]
      spaceWidth.tablet = props.width[1] || props.width[0]
      spaceWidth.mobile = props.width[2] || props.width[1] || props.width[0]
    } else {
      spaceWidth.desktop = props.width
      spaceWidth.tablet = props.width
      spaceWidth.mobile = props.width
    }
  }

  return {
    spaceHeight,
    spaceWidth
  }
})`
  flex-shrink: 0;
  width: ${props =>
    props.spaceWidth.desktop ? `${props.spaceWidth.desktop}px` : '100%'};
  height: ${props =>
    props.spaceHeight.desktop ? `${props.spaceHeight.desktop}px` : '100%'};

  ${props =>
    props.grow &&
    `
    flex-grow: 1;
  `}

  ${down('tablet')} {
    width: ${props =>
      props.spaceWidth.tablet ? `${props.spaceWidth.tablet}px` : '100%'};
    height: ${props =>
      props.spaceHeight.tablet ? `${props.spaceHeight.tablet}px` : '100%'};
  }

  ${down('mobile')} {
    width: ${props =>
      props.spaceWidth.mobile ? `${props.spaceWidth.mobile}px` : '100%'};
    height: ${props =>
      props.spaceHeight.mobile ? `${props.spaceHeight.mobile}px` : '100%'};
  }
`

export default Space
