import React from 'react'
import styled from 'styled-components'

import content from '../../content.json'
import Text from '../Text'
import Space from '../Space'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Footer = props => {
  return (
    <Container>
      <Text text={content.footer.heading} bold />
      <Space height={20} />
      <Text text={content.footer.email} onClick={props.onEmailClick} />
      <Text text={content.footer.phone} />
    </Container>
  )
}

export default Footer
